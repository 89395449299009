<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">

        <b-card>
          <h4><i class="nav-icon icon-plus"></i> Generate Invoice(s)</h4> <div v-if="adminVendor"> Vendor : {{adminVendor.company_name}} - {{adminVendor.fname}} {{adminVendor.lname}} </div>  
          <hr>

          <div>
          Select Company
          <multiselect @input="onSelectedCompany()" v-model="companySelection" placeholder="Search For Company" tag-placeholder="Search For Company" track-by="companyid" :options="listOfCompanies" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="name" >
          </multiselect>
          </div>


          <div v-if="companySelection && listOfProjects.length > 0">
          <hr>
            <h4>Price Agreement</h4> 
           <!--  {{listOfProjects}} -->
            Price Per Day
            <div class="form-group"><input class="form-control" type="number" step="any" min="0" max="100" v-model="pricePerDay"></div>
            Price Per AFE Count
            <div class="form-group"><input class="form-control" type="number" step="any" min="0" max="100" v-model="pricePerAfe"></div>
            Percent Of Total Invoices Cost
            <div class="form-group"><input class="form-control" type="number" step="any" min="0" max="100" v-model="percentTotal"></div>
          </div>


          <div v-if="companySelection && listOfProjects.length > 0">
          <hr>
            Select Projects
           <!--  {{listOfProjects}} -->
            <multiselect v-model="projectSelection" placeholder="Search For Project" tag-placeholder="Search For Project" track-by="projectid" :options="listOfProjects" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="projectname" >
            </multiselect>
          </div>

          <div v-if="projectSelection">
            <hr>
            
            <label for="">Start Date</label>
            <datepicker :class="{'is-danger': errors.has('startdate')}" class="admin-date rt-date-picker-admin" name="startdate" placeholder="Dropdown" format="yyyy-MM-dd" v-validate="'required'" v-model="startdate"></datepicker>

            <label for="">End Date</label>
            <datepicker :class="{'is-danger': errors.has('enddate')}" class="admin-date rt-date-picker-admin" name="enddate" placeholder="Dropdown" format="yyyy-MM-dd" v-validate="'required'" v-model="enddate"></datepicker>

            <hr>
            <button class="btn btn-success" @click="GenerateInvoiceForProject()">Generate Invoice For Project</button>
          </div>
        </b-card>

       <!--  <b-card>
          <h4><i class="nav-icon icon-plus"></i> Generate Invoice(s)</h4> <div v-if="adminVendor"> Vendor : {{adminVendor.company_name}} - {{adminVendor.fname}} {{adminVendor.lname}} </div>  
          <hr>
          <form @submit.prevent="validateBeforeSubmit">
            <label>Company</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend style="z-index: 99;">
                <b-input-group-text><i class="icon-settings"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <select class="select-account form-control" name="companyid" v-validate="'min_value:1'" v-model="companyid">
                <option value="0" disabled selected>Select Company</option>
                <option for v-for="option in companyData"  v-bind:value="option.companyid">{{option.name}}</option>
              </select>
              <select class="select-account form-control" name="companyid" v-validate="'min_value:1'" v-model="projectid">
                <option value="0" disabled selected>Select Project</option>
                <option for v-for="option in listOfProjects"  v-bind:value="option.projectId">{{option.projectname}}</option>
              </select>              
            </b-input-group>
            <i v-show="errors.has('companyid')" class="fa fa-exclamation-triangle mb-2"></i> <span v-show="errors.has('companyid')" class="help is-danger">The company account is required.</span>

            <label for="">Start Date</label>
            <datepicker :class="{'is-danger': errors.has('startdate')}" class="admin-date rt-date-picker-admin" name="startdate" placeholder="Dropdown" format="yyyy-MM-dd" v-validate="'required'" v-model="startdate"></datepicker>

            <label for="">End Date</label>
            <datepicker :class="{'is-danger': errors.has('enddate')}" class="admin-date rt-date-picker-admin" name="enddate" placeholder="Dropdown" format="yyyy-MM-dd" v-validate="'required'" v-model="enddate"></datepicker>

            <b-button variant="success" class="mt-4" type="submit" block>Generate Invoices</b-button>
          </form>
        </b-card> -->
        <div v-if="displayGeneratedInvoice">
          <b-card>

            <h3>Generated Invoice <button class="btn btn-success btn-lg float-right" @click="CreateInvoice()">Submit Invoice</button></h3>                                         
            <hr> 
              <table class="table">
                <thead>
                    <!-- Service	Code	Start - End Date	UOM 	Price/Unit	Quantity	Discount (%)	Total -->
                  <th>Service </th>
                  <th>Start - End Date</th>
                  <th>UOM</th>
                  <th>Price/Unit</th>
                  <th>Discounted Price/Unit</th>
                  <th>Quantity</th>
                  <th>Discount (%)</th>
                  <th>Total</th>
                </thead>
                  <tbody>
                    <tr v-for="line in listOfVendorServiceLines">
                      <td>{{line.name}}</td>
                      <td>{{line.start_date| moment("DD/MM/YYYY")}} - {{line.end_date| moment("DD/MM/YYYY")}}</td>
                      <td>{{line.uom}}</td> 
                      <td>{{line.pu}}</td>
                      <td>
                  {{line.discount ?  '$' + _discount_price_per_unit(line.discount,line.pu) : line.name ?  '$' + line.pu : null }}

                      </td>
                      <td>{{line.quantity}}</td>
                      <td>{{formatPrice(line.discount)}}</td>
                      <td>{{Math.round(line.pu * line.quantity * 100) / 100 }}</td>               
                    </tr>
                </tbody>
              </table>     
            <h1 class="float float-right">Total : <span style="color:green">${{formatPrice(rtnestInvoiceTotal)}} </span></h1> 
            <hr>           
            <table class="table">
              <thead>
                <th>AFE </th>
                <th>CC1</th>
                <th>CC2</th>
                <th>CC3</th>
                <th>Percent</th>
              </thead>
                <tbody>
                  <tr v-for="afe in allUniqeAfes">
                    <td>{{afe.afenum}}</td>
                    <td>{{afe.CC1}}</td>
                    <td>{{afe.CC2}}</td>
                    <td>{{afe.CC3}}</td>   
                    <td>{{Math.round(((afeDict[afe.service_ccid]/totalGroupInvoicesCost) * 100)* 100) / 100}}</td>               
                  </tr>
              </tbody>
            </table>   

            
          <div class="row mb-3 ml-3">
            <div class="col-md-12">
              <h3>Comment</h3> <hr>
            </div>                
              <div class="col-12 col-md-12 col-lg-12" style="color: #fff;">
                <input class="form-control" type="text"  style="width: 100%" placeholder="Comment" v-model="comments" value="">
              </div>
            </div>
            <hr>    
          </b-card>

          <b-card>
            <h3> Total Users: {{totalUsers}}</h3> <hr>
            <!-- users.vid_users + users.wss_users + users.wts_users + users.accountant_users -->
            vendors : {{users.vendor_count}} <br>wss : {{users.wss_count}} <br>wts : {{users.wts_count}} <br>managers : {{users.manager_count}} <br>accountants : {{users.accountant_count}}
          </b-card>
          <b-card>
            <table class="table">
              <thead>
                <th>Total</th>
                <th>Day Count</th>
                <th>AFE Count</th>
                <th>Invoice Total</th>
                <th>List Of AFES</th>
              </thead>
              <tbody>
                <tr v-for="line in feeData">
                  <td></td>
                  <td>{{line.dayCount}}</td>
                  <td>{{line.afeCount}}</td>
                  <td>${{formatPrice(line.invoiceTotal)}}</td>
                  <td>
                    <ul>
                        <li v-for="afe in line.afeTotals">
                          AFE Num: {{afe.afenum}} (Total: ${{formatPrice(afe.totals)}})<br>
                          Percent AFE Contributed: {{(afe.totals / line.invoiceTotal) * 100}}%
                          <!-- Total: {{(afe.totals / line.invoiceTotal) * 100}}% -->
                        </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import Multiselect from 'vue-multiselect'

export default {
  name: 'AdminCreateInvoice',
  components: {
    vSelect,
    Datepicker,
    Multiselect
  },
  data () {
    return {
      projectid: 0,
      companyid: 0,
      startdate: "2019-09-16",
      enddate: "2019-10-16",
      projectData: [],
      response: [],
      afeCount: 0,
      feeData: [],
      users: {},
      totalUsers: 0,
      uniqAfes : [],
      allUniqeAfes: [],
      afeDict : [], 
      totalGroupInvoicesCost: 0,
      adminVendorEmail : 'dev@rtnest.ca',
      serviceLines : [],
      adminVendor : null,
      listOfVendorServices : [],
      listOfVendorServiceLines: [],
      listOfCompanies: [],
      listOfProjects : [],
      companySelection: null,
      projectSelection : null,
      pricePerDay : 0,
      pricePerAfe : 0,
      percentTotal : 0,
      rtnestInvoiceTotal : 0,
      displayGeneratedInvoice: false,
      comments : ''
    }
  },
  methods: {
    onSelectedCompany() {
      //console.log(this.companySelection);
      this.pricePerDay = this.companySelection.price_per_day;
      this.pricePerAfe = this.companySelection.price_per_afe;
      this.percentTotal = this.companySelection.percent_total;

      let req = {
          "vid":this.adminVendor.vid, "companyId" : this.companySelection.companyid
        }
      this.$http.post('/admin/get/projects',req).then(res => {
          this.listOfProjects = [];
          this.listOfProjects = this.lodash.uniqBy(res.data.result,'projectname');
          this.listOfVendorServices = res.data.result;
          if(this.listOfProjects.length < 1){
              this.$toasted.show('No projects for this company', {type: 'error', duration: '3000'})
          }
      });
    },
    async GenerateInvoiceForProject(){
            let data = JSON.stringify({
              //projectid: this.projectid,
              companyid: this.companySelection.companyid,
              start_date: this.$moment(this.startdate).format('YYYY-MM-DD'),
              end_date: this.$moment(this.enddate).format('YYYY-MM-DD'),
            })
            this.$http.post('/admin/get/accounting/invoice_details', data)
            .then(response => {


              //  Filter out projects
              let res = response.data.result;
              res = res.filter(o => {
                return o.projectid === this.projectSelection.projectid;
              })

              

              this.feeData = [];     
              this.allUniqeAfes = this.lodash.uniqBy(res,'afenum');

              //Groups together all the invoices by invoice number
              // adds to "lines" which is each service line within the invoice
              var grouped = this.lodash.chain(res)
              .groupBy('invoiceid')
              .map((lines, invoiceid,) => ({invoiceid, lines }))
              .value();
              

              console.log("grouped");
              console.log(grouped);



              for (var i = 0; i < grouped.length; i++) {
                var groupOfLineItems = grouped[i].lines;

                this.uniqAfes = this.lodash.uniqBy(groupOfLineItems, "afenum");
                var afeCount = this.lodash.uniqBy(groupOfLineItems, "afenum").length;
                this.uniqAfes = this.uniqAfes.map(x=>x.afenum);
                
                //console.log('unqie afes');
                //console.log(this.uniqAfes);

                var sortedInvoices = groupOfLineItems.sort(function compare(a, b) {
                  var dateA = new Date(a.start_date);
                  var dateB = new Date(b.start_date);
                  return dateA - dateB;
                });

                var start = sortedInvoices[0].start_date;
                var end = sortedInvoices[sortedInvoices.length-1].end_date;
                var dayCount = this.$moment(end).diff(this.$moment(start), 'days') + 1;

                var invoiceTotal = groupOfLineItems.reduce(function(total, item){
                  return total + item.serveice_total
                },0);

                var afeTotals = [];

                for (var y = 0; y < this.uniqAfes.length; y++) {
                  var afeTotal = 0;
                  for (var x = 0; x < groupOfLineItems.length; x++) {

                    //  Select the lineitem for the AFE
                    if(groupOfLineItems[x].afenum == this.uniqAfes[y]){

                      let item = groupOfLineItems[x];
                      
                      afeTotal = invoiceTotal * (item.percentage/100); 

                      // afeTotal = afeTotal + (groupOfLineItems[x].percentage/100) * groupOfLineItems[x].serveice_total;
                      ////console.log(groupOfInvoices[x].afenum, groupOfInvoices[x].percentage, groupOfInvoices[x].serveice_total, afeTotal)
                    }
                  }
                  //console.log(this.uniqAfes[y]);
                  afeTotals.push({
                    "afenum": this.uniqAfes[y],
                    "totals": afeTotal,
                  });
                }

                this.feeData.push({
                  "afeCount": afeCount,
                  "dayCount": dayCount,
                  "invoiceTotal": invoiceTotal,
                  "afeTotals": afeTotals
                })

              }
              //console.log('fee data');
              //console.log(this.feeData);           
              this.afeCount = this.lodash.uniqBy(response.data.result, "afenum").length;

              this.feeData.forEach(afeGroup => {
                afeGroup.afeTotals.forEach(afe => {
                    this.allUniqeAfes.forEach(inv_serv => {
                        //console.log(inv_serv);
                        //console.log(afe);
                        if(afe.afenum === inv_serv.afenum){
                          afe.service_ccid = inv_serv.service_ccid;
                        }
                    })
                });
              })


              //console.log("GETTING INVOICE COST");
              this.totalGroupInvoicesCost = 0;
              this.feeData.forEach(fee => {
                this.totalGroupInvoicesCost +=  fee.invoiceTotal;
                //console.log(fee.invoiceTotal);
              })

              this.afeDict = [];
              this.afeDictPercent = []
              this.feeData.forEach(afeGroup => {
                afeGroup.afeTotals.forEach(afe => {
                    this.afeDict[afe.service_ccid] = 0;
                });
              })

              this.feeData.forEach(afeGroup => {
                afeGroup.afeTotals.forEach(afe => {
                    let initPercent = (afe.totals / afeGroup.invoiceTotal) * 100;
                    this.afeDict[afe.service_ccid] += (initPercent / 100) * afeGroup.invoiceTotal;
                });
              })




              //
              //  GET TOTAL USERS
              //
              this.$http.post('/admin/get/accounting/user_count', {"companyid" : this.companySelection.companyid}).then(async response => {
                  this.users = response.data.result;
                  //console.log('USER COUNT');
                  //console.log(this.users);


                  this.totalUsers = this.users.vendor_count + this.users.wss_count + this.users.wts_count + this.users.manager_count  + this.users.accountant_count ;  

                  //
                  //  Now create services for each service line 
                  // 
                  this.listOfVendorServiceLines = [];
                  this.rtnestInvoiceTotal =0;
                  this.listOfVendorServices = this.listOfVendorServices.filter(o => {
                    return o.projectid === this.projectSelection.projectid
                  })
                  this.listOfVendorServices.forEach(service => {

                    let quantity = 0;

                    //console.log('SERVICE NAME')
                    //console.log(service.vsid);

                    if(service.name === 'Invoice Charges'){
                      //  Figure out total first, then reverse-engineer the quantity
                      let chargeTotal = 0;
                      let totalDays = 0;
                      this.feeData.forEach(fee=> {
                          totalDays += fee.dayCount;
                      })  
                      chargeTotal += this.pricePerDay * totalDays;
                      chargeTotal += this.pricePerAfe * this.allUniqeAfes.length;
                      chargeTotal += this.totalGroupInvoicesCost * this.percentTotal;          
                      quantity = chargeTotal;                  
                    } 
                    if(service.name === "Number of active users"){
                      quantity = this.totalUsers; 
                    }
                    if(service.name === "Monthly Subscription"){
                      var date1 = this.$moment(this.startdate);
                      var date2 = this.$moment(this.enddate);
                      quantity = (date2.diff(date1,'days') + 1) / this.listOfProjects.length;
                    }

                    let payload = {
                      "name" : service.name,
                      "companyid" : this.companySelection.companyid,
                      "vid" : this.adminVendor.vid,
                      "currency" : "CAD",
                      "discount" : 0,
                      "start_date" : this.startdate,
                      "end_date" : this.enddate,
                      "projectid" : this.projectSelection.projectid,
                      "quantity" : quantity,
                      "pu" : service.pu1,
                      "uom" : service.uom1,
                      "serviceid" : service.serviceid 
                      }
                      this.rtnestInvoiceTotal += quantity * service.pu1;
                      this.listOfVendorServiceLines.push(payload);
                  })
                  this.displayGeneratedInvoice = true;

                  //
                  //  Create service lines by making actual API calls
                  //

                  //console.log('\n\n\nCREATING SERVICE LINES')

                  for(var service of this.listOfVendorServiceLines){
                    const wait = await this.$axios.post('/admin/create/service',service).then(resp=>{
                      //console.log('creating service lines');
                        //console.log(resp.data.vsid);
                        service.vsid = resp.data.vsid;
                    }).catch(err=> {
                      //console.log(err)
                    })
                  }

                  //console.log('\n\n\n DONE CREATING SERVICE LINES');
                  //console.log('\n\n\n CREATE AFE PERCENTAGE');

                  for(var afe of this.allUniqeAfes){
                    for(var service of this.listOfVendorServiceLines){
                      let payload = {
                          vid : this.adminVendor.vid,
                          companyid: this.companySelection.companyid,
                          projectid: this.projectSelection.projectid,
                          percentage: (this.afeDict[afe.service_ccid]/this.totalGroupInvoicesCost) * 100 ,
                          vsid: service.vsid,
                          service_ccid: afe.service_ccid,
                      }
                      //console.log(payload);
                      const wait = await this.$axios.post('/admin/create/afe_percentage',payload).then(resp=>{
                        //console.log(resp);
                      }).catch(err=>{
                        //console.log(err);
                      });
                    }
                  }

                  //console.log('\n\n\n\n CREATE AFE PERCENTAGE DONE \n\n\n\n')
                  
              });
            })
            .catch(error => {
              //console.log(error)
            })
    },

    CreateInvoice(){
      /* {
        "services": [
          10,
          11
        ],
        "draft": 1,
        "vwref": "WELL_NUMBER_OR_UWI_FROM_GET_SERVICE_CHAINS",
        "companyid": 1,
        "projectid": 1,
        "tax_percentage": 5,
        "vendor_note": "oh Hi Mark",
        "discount": 10,
        "quickpay": 1,
        "endtday": "2019-01-18"
        } */

        let listOfVsids = [];
        for(var service of this.listOfVendorServiceLines){
          listOfVsids.push(service.vsid);
        }

        let payload = {
          services : listOfVsids,
          draft:0,
          vwref:"rtn-invoice-well",
          projectid: this.projectSelection.projectid,
          companyid: this.companySelection.companyid,
          tax_percentage: 5,
          vendor_note: "",
          discount: 0,
          quickpay: 0,
          endtday: "2019-06-07",
          projectname: this.projectSelection.projectname,
          vid : this.adminVendor.vid,
          totalprice : this.rtnestInvoiceTotal,
          comment : this.comments    
        }

        this.$axios.post('admin/create/invoice',payload).then(resp=>{
          //console.log("\n\n\n\n CREATED INVOICE! \n\n\n\n");
              this.$toasted.show('Invoice Created!', {type: 'success', duration: '3000'});
              this.$router.go();
        }).catch(err=>{
          //console.log(err);
        })

    }
  },
  mounted() {
    this.$http.get('/admin/get/companies')
    .then(response => {
      this.listOfCompanies = response.data.result;
          this.$http.get('/admin/get/vendors').then(response => {
            let vendors = response.data.result
            this.adminVendor = vendors.find( v => {
                return v.email === this.adminVendorEmail
            })
            //console.log("Found Admin Vendor");
            //console.log(this.adminVendor);
          }) 
      })
    .catch(error => {
    })
  },
  computed: {

  }
}

</script>
<style media="screen">
  .rt-date-picker-admin input {
    padding: 6px !important;
  }
</style>
