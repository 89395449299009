var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c("b-card", [
                _c("h4", [
                  _c("i", { staticClass: "nav-icon icon-plus" }),
                  _vm._v(" Generate Invoice(s)")
                ]),
                _vm.adminVendor
                  ? _c("div", [
                      _vm._v(
                        " Vendor : " +
                          _vm._s(_vm.adminVendor.company_name) +
                          " - " +
                          _vm._s(_vm.adminVendor.fname) +
                          " " +
                          _vm._s(_vm.adminVendor.lname) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("hr"),
                _c(
                  "div",
                  [
                    _vm._v("\n        Select Company\n        "),
                    _c("multiselect", {
                      attrs: {
                        placeholder: "Search For Company",
                        "tag-placeholder": "Search For Company",
                        "track-by": "companyid",
                        options: _vm.listOfCompanies,
                        multiple: false,
                        "close-on-select": true,
                        "clear-on-select": false,
                        "preserve-search": true,
                        taggable: true,
                        label: "name"
                      },
                      on: {
                        input: function($event) {
                          return _vm.onSelectedCompany()
                        }
                      },
                      model: {
                        value: _vm.companySelection,
                        callback: function($$v) {
                          _vm.companySelection = $$v
                        },
                        expression: "companySelection"
                      }
                    })
                  ],
                  1
                ),
                _vm.companySelection && _vm.listOfProjects.length > 0
                  ? _c("div", [
                      _c("hr"),
                      _c("h4", [_vm._v("Price Agreement")]),
                      _vm._v("\n          Price Per Day\n          "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pricePerDay,
                              expression: "pricePerDay"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "any",
                            min: "0",
                            max: "100"
                          },
                          domProps: { value: _vm.pricePerDay },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.pricePerDay = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v("\n          Price Per AFE Count\n          "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.pricePerAfe,
                              expression: "pricePerAfe"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "any",
                            min: "0",
                            max: "100"
                          },
                          domProps: { value: _vm.pricePerAfe },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.pricePerAfe = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(
                        "\n          Percent Of Total Invoices Cost\n          "
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.percentTotal,
                              expression: "percentTotal"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "any",
                            min: "0",
                            max: "100"
                          },
                          domProps: { value: _vm.percentTotal },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.percentTotal = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _vm.companySelection && _vm.listOfProjects.length > 0
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _vm._v("\n          Select Projects\n         "),
                        _c("multiselect", {
                          attrs: {
                            placeholder: "Search For Project",
                            "tag-placeholder": "Search For Project",
                            "track-by": "projectid",
                            options: _vm.listOfProjects,
                            multiple: false,
                            "close-on-select": true,
                            "clear-on-select": false,
                            "preserve-search": true,
                            taggable: true,
                            label: "projectname"
                          },
                          model: {
                            value: _vm.projectSelection,
                            callback: function($$v) {
                              _vm.projectSelection = $$v
                            },
                            expression: "projectSelection"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.projectSelection
                  ? _c(
                      "div",
                      [
                        _c("hr"),
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Start Date")
                        ]),
                        _c("datepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "admin-date rt-date-picker-admin",
                          class: { "is-danger": _vm.errors.has("startdate") },
                          attrs: {
                            name: "startdate",
                            placeholder: "Dropdown",
                            format: "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.startdate,
                            callback: function($$v) {
                              _vm.startdate = $$v
                            },
                            expression: "startdate"
                          }
                        }),
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("End Date")
                        ]),
                        _c("datepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          staticClass: "admin-date rt-date-picker-admin",
                          class: { "is-danger": _vm.errors.has("enddate") },
                          attrs: {
                            name: "enddate",
                            placeholder: "Dropdown",
                            format: "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.enddate,
                            callback: function($$v) {
                              _vm.enddate = $$v
                            },
                            expression: "enddate"
                          }
                        }),
                        _c("hr"),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            on: {
                              click: function($event) {
                                return _vm.GenerateInvoiceForProject()
                              }
                            }
                          },
                          [_vm._v("Generate Invoice For Project")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm.displayGeneratedInvoice
                ? _c(
                    "div",
                    [
                      _c("b-card", [
                        _c("h3", [
                          _vm._v("Generated Invoice "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success btn-lg float-right",
                              on: {
                                click: function($event) {
                                  return _vm.CreateInvoice()
                                }
                              }
                            },
                            [_vm._v("Submit Invoice")]
                          )
                        ]),
                        _c("hr"),
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("th", [_vm._v("Service ")]),
                            _c("th", [_vm._v("Start - End Date")]),
                            _c("th", [_vm._v("UOM")]),
                            _c("th", [_vm._v("Price/Unit")]),
                            _c("th", [_vm._v("Discounted Price/Unit")]),
                            _c("th", [_vm._v("Quantity")]),
                            _c("th", [_vm._v("Discount (%)")]),
                            _c("th", [_vm._v("Total")])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.listOfVendorServiceLines, function(
                              line
                            ) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(line.name))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        line.start_date,
                                        "DD/MM/YYYY"
                                      )
                                    ) +
                                      " - " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          line.end_date,
                                          "DD/MM/YYYY"
                                        )
                                      )
                                  )
                                ]),
                                _c("td", [_vm._v(_vm._s(line.uom))]),
                                _c("td", [_vm._v(_vm._s(line.pu))]),
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        line.discount
                                          ? "$" +
                                              _vm._discount_price_per_unit(
                                                line.discount,
                                                line.pu
                                              )
                                          : line.name
                                          ? "$" + line.pu
                                          : null
                                      ) +
                                      "\n\n                    "
                                  )
                                ]),
                                _c("td", [_vm._v(_vm._s(line.quantity))]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.formatPrice(line.discount)))
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      Math.round(
                                        line.pu * line.quantity * 100
                                      ) / 100
                                    )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ]),
                        _c("h1", { staticClass: "float float-right" }, [
                          _vm._v("Total : "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatPrice(_vm.rtnestInvoiceTotal)
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("hr"),
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("th", [_vm._v("AFE ")]),
                            _c("th", [_vm._v("CC1")]),
                            _c("th", [_vm._v("CC2")]),
                            _c("th", [_vm._v("CC3")]),
                            _c("th", [_vm._v("Percent")])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.allUniqeAfes, function(afe) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(afe.afenum))]),
                                _c("td", [_vm._v(_vm._s(afe.CC1))]),
                                _c("td", [_vm._v(_vm._s(afe.CC2))]),
                                _c("td", [_vm._v(_vm._s(afe.CC3))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      Math.round(
                                        (_vm.afeDict[afe.service_ccid] /
                                          _vm.totalGroupInvoicesCost) *
                                          100 *
                                          100
                                      ) / 100
                                    )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ]),
                        _c("div", { staticClass: "row mb-3 ml-3" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("h3", [_vm._v("Comment")]),
                            _c("hr")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "col-12 col-md-12 col-lg-12",
                              staticStyle: { color: "#fff" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.comments,
                                    expression: "comments"
                                  }
                                ],
                                staticClass: "form-control",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "text",
                                  placeholder: "Comment",
                                  value: ""
                                },
                                domProps: { value: _vm.comments },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.comments = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]),
                        _c("hr")
                      ]),
                      _c("b-card", [
                        _c("h3", [
                          _vm._v(" Total Users: " + _vm._s(_vm.totalUsers))
                        ]),
                        _c("hr"),
                        _vm._v(
                          "\n          vendors : " +
                            _vm._s(_vm.users.vendor_count) +
                            " "
                        ),
                        _c("br"),
                        _vm._v("wss : " + _vm._s(_vm.users.wss_count) + " "),
                        _c("br"),
                        _vm._v("wts : " + _vm._s(_vm.users.wts_count) + " "),
                        _c("br"),
                        _vm._v(
                          "managers : " + _vm._s(_vm.users.manager_count) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          "accountants : " +
                            _vm._s(_vm.users.accountant_count) +
                            "\n        "
                        )
                      ]),
                      _c("b-card", [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("th", [_vm._v("Total")]),
                            _c("th", [_vm._v("Day Count")]),
                            _c("th", [_vm._v("AFE Count")]),
                            _c("th", [_vm._v("Invoice Total")]),
                            _c("th", [_vm._v("List Of AFES")])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.feeData, function(line) {
                              return _c("tr", [
                                _c("td"),
                                _c("td", [_vm._v(_vm._s(line.dayCount))]),
                                _c("td", [_vm._v(_vm._s(line.afeCount))]),
                                _c("td", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(_vm.formatPrice(line.invoiceTotal))
                                  )
                                ]),
                                _c("td", [
                                  _c(
                                    "ul",
                                    _vm._l(line.afeTotals, function(afe) {
                                      return _c("li", [
                                        _vm._v(
                                          "\n                        AFE Num: " +
                                            _vm._s(afe.afenum) +
                                            " (Total: $" +
                                            _vm._s(
                                              _vm.formatPrice(afe.totals)
                                            ) +
                                            ")"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                        Percent AFE Contributed: " +
                                            _vm._s(
                                              (afe.totals / line.invoiceTotal) *
                                                100
                                            ) +
                                            "%\n                        "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }